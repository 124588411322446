<template>
  <div>
    <ckeditor
      id="editor"
      :editor="editor"
      @ready="onReady"
      @input="setData"
      :value="editorData"
      :config="editorConfig"
    ></ckeditor>
  </div>
</template>

<script>
const upload = require('@/components/ckeditor/upload');
import CKEditor from '@ckeditor/ckeditor5-vue2';
import classic from './ckeditor5-build-classic';
/*  import DecoupledEditor from'@ckeditor/ckeditor5-build-decoupled-document';*/
import '@ckeditor/ckeditor5-build-classic/build/translations/zh';

export default {
  name: 'editor',
  props: ['editorData'],
  components: {
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      editor: classic,
      // editorData: '',
      editorConfig: {
        language: 'zh-cn', //中文包
        removePlugins: ['Heading'],
        extraPlugins: [upload.MyCustomUploadAdapterPlugin],
        image: {
          styles: ['full', 'alignLeft', 'alignRight'],
          toolbar: [
            'imageStyle:alignLeft',
            'imageStyle:full',
            'imageStyle:alignRight',
            '|',
            'imageResize:original',
            'imageResize:50',
            'imageResize:75',
          ],
          resizeOptions: [
            {
              name: 'imageResize:original',
              value: null,
              icon: 'original',
            },
            {
              name: 'imageResize:50',
              value: '50',
              icon: 'medium',
            },
            {
              name: 'imageResize:75',
              value: '75',
              icon: 'large',
            },
          ],
        },
        toolbar: [
          'alignment',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          '|',
          'imageUpload',
          'blockQuote',
          'insertTable',
          '|',
          'undo',
          'redo',
        ],
      },
    };
  },
  methods: {
    onReady(editor) {
      // 在可编辑区域之前插入工具栏。
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
    },
    emptyEditor() {
      // this.editorData = '';
      this.setData('');
    },
    setData(value = this.editorData) {
      this.$emit('setData', value);
    },
    focus() {
      let dom = document.getElementById('editor');
      console.log(dom, dom.autofocus);
      console.dir(dom);
      // dom.autofocus = true
    },
  },
};
</script>
<style lang="less">
.ck-editor__editable {
  min-height: 157px;
  max-height: 157px;
  width: 100%;
  overflow: auto;
  border-radius: 0 0 4px 4px !important;
  border-top: none !important;
  border-bottom: 1px solid #e8ecef !important;
  border-left: 1px solid #e8ecef !important;
  border-right: 1px solid #e8ecef !important;
  //由于ul和ol默认样式已更改所以需要在这里改回来
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
}
.ck.ck-toolbar {
  border-radius: 4px 4px 0 0 !important;
  display: block;
  color: #c2c7d9;
  background: none;
  background: rgba(0, 0, 0, 0);
  border: 1px solid #e8ecef;
  border-bottom: none;
}
.ck.ck-toolbar .ck.ck-toolbar__separator {
  background: #e8ecef;
}
.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  outline: none;
  border: none;
  /*box-shadow:none;*/
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1) inset;
}
</style>
