import axios from 'axios';
class MyUploadAdapter {
  constructor(loader) {
    // 要在上载期间使用的文件加载器实例
    this.loader = loader;
  }
  // 启动上载过程
  upload() {
    return this.loader.file.then(
      file =>
        new Promise(resolve => {
          this.uploadFile(file, resolve);
        }),
    );
  }
  uploadFile(file, resolve) {
    //上传图片
    let fd = new FormData();
    fd.append('uploadfile', file); //数据
    axios({
      url: 'https://up.eai9.com', //请求上传文件返回url
      method: 'post',
      data: fd,
      headers: {
        'content-type': 'multipart/form-data',
      },
    }).then(res => {
      resolve({ default: res.data.data });
    });
  }
}
function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = loader => {
    return new MyUploadAdapter(loader);
  };
}
export { MyUploadAdapter, MyCustomUploadAdapterPlugin };
